import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Html from "../components/html"

const Features = ({ items }) => {

  return (
    <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
      {items.map((feature) => (
        <div
          className="flex flex-col shadow-lg overflow-hidden"
          key={feature.title}
        >
          <div className="flex-shrink-0">
            <Link to={feature.link} className="block">
              <Img
                className="h-56 w-full object-cover"
                fluid={feature.image.childImageSharp.fluid}
                alt={feature.title}
              />
            </Link>
          </div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <Link to={feature.link} className="block">
                <h3 className="mt-2 text-center text-xl leading-7 font-semibold text-gray-900">
                  {feature.title}
                </h3>
                <Html
                  className="mt-3 text-base text-center leading-6 text-gray-500"
                  content={feature.text}
                />
              </Link>
            </div>
            <div className="mt-3 flex items-center justify-center">
              <div className="inline-flex mb-1">
                <Link
                  to={feature.link}
                  title="En savoir plus"
                  className="inline-flex items-center justify-center text-base leading-6 font-medium text-green-600 transition duration-150 ease-in-out"
                >
                  En savoir plus{" "}
                  <svg
                    className="h-6 w-6 pl-1 inline-block"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M9 5l7 7-7 7"></path>
                  </svg>
                  
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

Features.propTypes = {
  title: PropTypes.string,
  items: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default Features;